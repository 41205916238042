// auth - firebase mail link
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { NumberFormat } from '../../services/srvc-utilities'

import WebbDividerSmall from '../webx/webb-divider-sm'
import WebbDividerMedium from '../webx/webb-divider-md'

import NavsButtonBack from '../webx/navs-button-back'
import NavsButtonNext from '../webx/navs-button-next'

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import { TransfersFundList } from "../../services/srvc-transfers-funds-realm";
import { TransfersAssetList } from '../../services/srvc-transfers-assets-realm'

const list = require('../../data.static/data-transfers-status.json').data 


export default function TransfersListModule (props) {
  // console.log(props.search)

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()

  const [loader, setLoader] = useState(true)
  const [data, setData] = useState([])

  const [search, setSearch] = useState()

  const [index, setIndex] = useState(1)
  const [items, setItems] = useState(5)

  const [curr, setCurrentIndex] = useState(1)
  const [next, setNextIndex] = useState()
  const [last, setLastIndex] = useState()

  const [count, setCount] = useState()
  const [total, setTotal] = useState()

  const [text, setText] = useState('')

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)

        const resultAssets = await TransfersAssetList({
          data: {
            user: asset.item,
            index: index.toString(), items: items.toString(),
            filters: { name: props.search }
          },
          client: asset?.creds?.client,
          service: asset?.creds?.service
        })
        //console.log (resultAssets)

        const resultFunds = await TransfersFundList({
          data: {
            user: asset.item,
            index: index.toString(), items: items.toString(),
            filters: { name: props.search }
          },
          client: asset?.creds?.client,
          service: asset?.creds?.service
        })        
        //console.log (resultFunds)

        if (resultAssets.stat || resultFunds.stat) {
          const datx = [
            ...Array.from(resultAssets.data.list, x => { return {
              ...x,
              amount: x.size
            }}), 
            
            ...Array.from(resultFunds.data.list, x => { return {
              ...x,
              amount: x.sale
            }}), 
          ]
          setData(datx.sort((a, b) => b.created - a.created))

          const resTotal = resultAssets.data.count + resultFunds.data.count
          setTotal(resultAssets.data.count + resultFunds.data.count)

          // setText(
          //   `${(index - 1) * items + 1} - ${  // index -1, item
          //     index * items < resTotal ? index * items : resTotal
          //   } of ${resTotal}`
          // )
        }

        setLoader(false)
      }
      fetchData()
    } else {
    }
  }, [props.search, index, items])

  const NextIndex = async () => {
    if (data.length < items) {
    } else {
      setNextIndex(curr + 1)
      setIndex(curr + 1)
      setCurrentIndex(curr + 1)
    }
  }

  const LastIndex = async () => {
    if (index == 1) {
    } else {
      setLastIndex(curr - 1)
      setIndex(curr - 1)
      setCurrentIndex(curr - 1)
    }
  }

  // console.log(data)

  if (loader) return <>
    <div className='p-3 back-color-wite rounded-xd border align-middle' style={{}}>

      <span className="align-middle text-lead">
        <i className="bx bxs-info-circle text-color-wait"></i>
      </span>
      <span className='ms-1 text-color-tone'>Please Wait...</span>

    </div>
  </>

  if (!loader && (!data || data.length === 0))
  return <>No Transfers</>

  
  return (
    <>
      <div className={'back-color-wite border rounded-xd'}>
      
      {/* header */}
      <div className="border-bottom text-small text-bold">
        <div className="d-flex p-2 px-3">

          <div className="" style={{width: '5%'}}>         
            <p className="m-0">
              <span className="text-small">
                <i className="bx bxs-circle text-color-tint"></i>
              </span>
            </p>
          </div>  

          <div className="" style={{minWidth: '65%'}}>
            <p className="m-0">
              <span className="">{'Details'}</span>
            </p>
          </div>    

          <div className="ms-auto text-end text-sm" style={{width: '30%'}}>         
            <p className="m-0 text-sm ">
              <span className="">{'Amount'}</span>
              <span className="d-md-none d-none">{'Status'}</span>
            </p>
          </div>

        </div>
      </div>

      {/* data */} 
      {data && data.map((item, i) => (
        <div key={i}>
          <div className="d-flex p-2 px-3">
            <div className="" style={{width: '5%'}}>         
              <p className="m-0">
                <span className={`text-small text-${list.find(x => x.code == item?.status ).color}`}>
                  <i className={`bx bx bxs-circle ${item.feat.form == 'assets' ? '': 'd-none'}`}></i>
                  <i className={`bx bx bxs-circle ${item.feat.form == 'funds' ? '': 'd-none'}`}></i>
                </span>
              </p>
            </div>  

            <div className="" style={{width: '65%'}}>
              <p className="m-0">
                <span className="text-bold">{item.user.name}</span>
              </p>
              <p className="m-0 text-small text-sm">{item?.meta?.memo || '******'}</p>
              <p className="m-0 text-small">
                <span>{(new Date(parseInt(item?.created))).toLocaleString() || '******'}</span>
              </p>
            </div>

            <div className="ms-auto text-end" style={{width: '25%'}}>
              <p className={`m-0 ${item.feat.form == 'funds' ? 'd-none' : ''}`}>
                <span className="ms-1 d-none">{item.mode =='credit' ? '+' : '-'}</span>
                <span className="">1</span> 
                {/* {item?.amount?.number || '0'} */}
                <span className="ms-1"></span>
                <span className="text-mini p-1 back-color-lite rounded">{item?.amount?.ticker || ''}</span>
              </p>
              <p className={`m-0 ${item.feat.form == 'funds' ? '' : 'd-none'}`}>
                <span className="ms-1">{item.mode =='credit' ? '+' : '-'}</span>
                <span className="">{item?.amount?.number || '1'}</span>
                <span className="ms-1"></span>
                <span className="text-mini p-1 back-color-lite rounded">{item?.amount?.ticker || ''}</span>
              </p>
            </div>
          </div>
          <div className={i < data.length-1 ? 'border-bottom': ''}></div>
        </div>
      ))}
      
      </div>


      {/* navs */}
      <div className={loader ? 'd-none' : ''}>
        <WebbDividerSmall />
        <div className={data.length < items ? '' : ''}>
          <div className='d-flex justify-content-between'>
            <div className='' onClick={() => LastIndex()}>
              <NavsButtonBack />
            </div>

            <div className=''>
              <p className='my-3'>{text}</p>
            </div>

            <div className=''  onClick={() => NextIndex()}>
              <NavsButtonNext />
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
